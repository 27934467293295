@import "assets/fonts/en/stylesheet.css";
@import "assets/fonts/roboto/stylesheet.css";
@import "./layout.scss";

body {
  box-sizing: border-box;
  height: 100%;
  font-family: "FWD Circular TT" sans-serif;
}
.ant-btn {
  height: fit-content;
  line-height: fit-content;
}
// A simple app loading spinner
.loader-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-height: 3em;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #e87722;
    font-size: 10px;
    // margin: 20px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    display: inline-block;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}

// The nz-notification panel
.notification {
  width: 50vw;
}

// Custom Scrollbar
.custom-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
  scrollbar-width: thin;

  &.scroll-auto-hide {
    scrollbar-color: transparent transparent;
  }
  &.scroll-auto-hide:hover {
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
  }
  &::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    background: rgba(0, 0, 0, 0.2);
    background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:vertical {
    min-height: 30px;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px;
  }
  &.scroll-auto-hide::-webkit-scrollbar-thumb {
    background: transparent;
    background-clip: padding-box;
  }
  &.scroll-auto-hide:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
    background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
    background-clip: padding-box;
  }

  &-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.margin-l-8 {
  margin-left: 8px;
}
.margin-t-8 {
  margin-top: 8px;
}
.margin-t-16 {
  margin-top: 16px;
}

.ant-avatar-string {
  position: initial !important;
  left: auto;
}

.ant-tabs-dropdown-menu-item a[nz-tab-link] {
  position: relative !important;
}

a[nz-tab-link] {
  position: static !important;
}
