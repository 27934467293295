$color-base: #d9d9d9;

#dms-form.grid-layout {
  display: grid;
  align-items: start;
  align-content: flex-start;
}
.dms-field {
  padding: 0px 15px 15px 0px;
}
.content-margin-no-right{
  padding-right: 0px;
}
 .content-margin-no-bottom{
  padding-bottom: 0px;
}
.content-margin-no-top{
  padding-top: 0px;
}
 .content-margin-no-left{
  padding-left: 0px;
}
.content-margin-keep{
  padding: 0px 15px 15px 0px;
}
.grid-layout {
  .dms-field__content {
    margin: 0;

    &__label {
      min-width: fit-content;
      width: auto;
    }
  }
}

.dms-form.grid-layout {
  @for $i from 1 through 32 {
    .start-#{$i} {
      grid-column-start: $i;
    }

    .end-#{$i} {
      grid-column-end: col-end $i;
    }

    &.grid-columns-#{$i} {
      grid-template-columns: repeat($i, [col-start] 1fr [col-end]);
    }
  }
}

.label-vertical {
  .dms-field {
    &__content {
      display: grid;
      grid-auto-flow: row;
      &__label {
        text-align: left;
      }
    }
  }
}

.dms-field.label-vertical {
  .dms-field__content {
    display: grid;
    grid-auto-flow: row;
    &__label {
      text-align: left;
    }
  }
}

.label-horizontal {
  .dms-field {
    &__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &__label {
        min-width: 25%;
        width: 25%;
        label {
          white-space: normal;
          height: fit-content;
        }
      }
    }
  }
}

.label-horizontal.dms-field {
  .dms-field__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &__label {
      min-width: 25%;
      width: 25%;
      label {
        white-space: normal;
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #dms-form.grid-layout {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    align-content: flex-start;

    .dms-field {
      &__content {
        display: grid;
        grid-auto-flow: row;
        &__label {
          width: auto;
          text-align: left;
        }
      }
    }
  }
}

//
// Form Field Styles
//

// Static: static

.static-divider {
  .dms-field__content__label--static {
    text-align: left;
    width: fit-content;
    min-width: fit-content;
    width: 100%;
    label{
      width: 100%;
    }
  }
  .inner-control {
    display: flex;
    align-items: center;
  }
  .inner-control_content {
    margin: 0 6px;
  }
  .inner-control::before {
    content: "";
    flex: 1;
    height: 1px;
    background: $color-base;
  }
  .inner-control::after {
    content: "";
    flex: 28;
    height: 1px;
    background: $color-base;
  }
  .inner-label {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .inner-label_content {
    margin: 0 6px;
  }
  .inner-label::before {
    content: "";
    flex: 1;
    height: 1px;
    background: $color-base;
  }
  .inner-label::after {
    content: "";
    flex: 28;
    height: 1px;
    background: $color-base;
  }
}
