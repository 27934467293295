@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-Black.woff2") format("woff2"), url("FWDCircularTT-Black.woff") format("woff"),
    url("FWDCircularTT-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-Bold.woff2") format("woff2"), url("FWDCircularTT-Bold.woff") format("woff"),
    url("FWDCircularTT-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-Medium.woff2") format("woff2"), url("FWDCircularTT-Medium.woff") format("woff"),
    url("FWDCircularTT-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-Book.woff2") format("woff2"), url("FWDCircularTT-Book.woff") format("woff"),
    url("FWDCircularTT-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-BlackItalic.woff2") format("woff2"), url("FWDCircularTT-BlackItalic.woff") format("woff"),
    url("FWDCircularTT-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-BoldItalic.woff2") format("woff2"), url("FWDCircularTT-BoldItalic.woff") format("woff"),
    url("FWDCircularTT-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-MediumItalic.woff2") format("woff2"), url("FWDCircularTT-MediumItalic.woff") format("woff"),
    url("FWDCircularTT-MediumItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularTT-BookItalic.woff2") format("woff2"), url("FWDCircularTT-BookItalic.woff") format("woff"),
    url("FWDCircularTT-BookItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
/* @font-face {
  font-family: "FWD Circular TT";
  src: url("FWDCircularWeb-Book.woff2") format("woff2");
  font-display: swap;
} */
